import React from "react";
import s from "../style/footer.module.css";
import logo from "../assets/svg/logo.svg";
import mail from "../assets/svg/sms.svg";
import { TiSocialLinkedin } from "react-icons/ti";
import { FaInstagram } from "react-icons/fa";
import { RiTiktokFill } from "react-icons/ri";
import { useState } from "react";
import { useEffect } from "react";

const Footer = ({ setSubscribeVisible, setSubscribeVisibleExists }) => {
  const [data, setData] = useState([]);
  const [email, setEmail] = useState("");

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/getHomeTexts`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      });
  }, []);

  function subscribe() {
    if (email === "") {
      alert("Please enter your email");
      return;
    }
    const api = `${process.env.REACT_APP_API_URL}/subscribe`;
    const postData = {
      email: email,
      type: "footer",
    };

    fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "subscribed") {
          setSubscribeVisible(true);
        } else {
          setSubscribeVisibleExists(true);
        }
      });
  }

  function preventMail(event) {
    const regex = /^[a-zA-Z0-9@.]$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  return (
    <div className={s.footerDiv}>
      <div className={s.footerTop}>
        <ul>
          <img className={s.footerLogo} src={logo} />
          <div className={s.footerUlLine}></div>
          <li onClick={() => window.open("https://bilikidiaries.com/")}>Blog</li>
          <li onClick={() => (window.location.href = "/AboutUs")}>About us</li>
          <li onClick={() => window.open("mailto:info@bilikiapp.com")}>Contact</li>
          <li onClick={() => (window.location.href = "/privacy-policy")}>Privacy Policy</li>
          <li onClick={() => (window.location.href = "/Terms")}>Terms and Conditions</li>
        </ul>
      </div>
      <div className={s.footerBot}>
        <div className={s.footerLeft}>
          <p>{data["partnership text"]}</p>
          <button onClick={() => window.open("mailto:info@bilikiapp.com")}>Partnership</button>
        </div>
        <div className={s.footerMiddle}>
          <p>Subscribe to get more info</p>
          <div className={`${s.plantTreeMailDiv} ${s.plantTreeMailDiv2}`}>
            <img src={mail}></img>
            <input
              onKeyPress={preventMail}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder="Your Email"
            ></input>
            <button onClick={subscribe}>Subscribe</button>
          </div>
        </div>

        <div className={s.footerRight}>
          <TiSocialLinkedin onClick={() => window.open("https://www.linkedin.com/company/biliki-ai/")} size={45} />
          <FaInstagram onClick={() => window.open("https://www.instagram.com/biliiki.ai/")} size={35} />
          <RiTiktokFill onClick={() => window.open("https://www.tiktok.com/@bilikiai")} size={35} />
        </div>
      </div>
    </div>
  );
};

export default Footer;
