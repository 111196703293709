import React from "react";
import s from "../style/mainTourBlock.module.css";
import location from "../assets/svg/location.svg";
import calendar from "../assets/svg/calendar.svg";

const MainTourBlock = ({ destination, length, img, tour }) => {
  function openTour() {
    window.open(tour, "_blank");
  }

  return (
    <div className={s.wrapper}>
      <div
        onClick={openTour}
        className={s.imageDiv}
        style={{ backgroundImage: `url(${img})` }}
      ></div>{" "}
      <div className={s.descDiv}>
        <div className={s.locationDiv}>
          <img src={location} />
          <p>{destination}</p>
        </div>
        <div className={s.lengthDiv}>
          <img src={calendar} />
          <p>{length} days</p>
        </div>
      </div>
    </div>
  );
};

export default MainTourBlock;
