import React, { useEffect, useState } from "react";
import { scroller } from "react-scroll";
import s from "../style/day.module.css";
import edit from "../assets/svg/Frame 147.svg";
import location from "../assets/svg/location.svg";
import tree from "../assets/svg/treeplant.svg";
import free from "../assets/svg/Freetour.svg";
import footerHouse from "../assets/svg/footerHouse.svg";
import TourLoader from "../components/TourLoader";

const Day = ({
  freeDoesNotExist,
  isRegenerating,
  setEditDayNum,
  editDayNum,
  setRegenerateVisible,
  data,
  dayIndex,
  currentDaysLength,
}) => {
  const [viatorExists, setViatorExists] = useState(false);
  const [viatorLinks, setViatorLinks] = useState([]);
  const [viatorImages, setViatorImages] = useState([]);
  const [viatorTitles, setViatorTitles] = useState([]);
  const [dayNames, setDayNames] = useState([]);
  const [loaderShouldShow, setLoaderShouldShow] = useState(false);

  useEffect(() => {
    if (isRegenerating && dayIndex + 1 === editDayNum) {
      setLoaderShouldShow(true);
    } else {
      setLoaderShouldShow(false);
    }
  }, [isRegenerating, dayIndex, editDayNum]);

  function StringToParagraph({ text }) {
    return <p className={s.text} dangerouslySetInnerHTML={{ __html: text }} />;
  }

  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const start = url.searchParams.get("startDate");
    const end = url.searchParams.get("endDate");
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const result = [];
    const startDate = new Date(start);
    const endDate = new Date(end);
    for (
      let date = new Date(startDate);
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      result.push(dayNames[date.getDay()]);
    }
    setDayNames(result);
  }, []);

  useEffect(() => {
    if (!data.viator_links) {
      return;
    }
    const viatorLinksTemp = data.viator_links.split(",");
    const viatorImagesTemp = data.viator_img.split(",");
    const viatorTitlesTemp = data.viator_name.split(",");
    let newViatorTitlesTemp = [];
    if (viatorLinksTemp[0] !== "") {
      setViatorExists(true);
    }
    for (let i = 0; i < viatorTitlesTemp.length; i++) {
      let tempName = viatorTitlesTemp[i].split("(");
      newViatorTitlesTemp.push(tempName[0]);
    }
    setViatorLinks(viatorLinksTemp);
    setViatorImages(viatorImagesTemp);
    setViatorTitles(newViatorTitlesTemp);
  }, [data.trip_from, data.trip_to]);

  const scrollToDay = (index) => {
    if (index === "freeTourWrapper") {
      scroller.scrollTo("freeTourWrapper", {
        duration: 500,
        smooth: true,
      });
      return;
    } else {
      scroller.scrollTo(`day-${index}`, {
        duration: 500,
        smooth: true,
      });
    }
  };

  function BookingCity(city, startDate, endDate) {
    let link1 = `https://www.booking.com/searchresults.en-gb.html?aid=7905319&checkin=${startDate}&checkout=${endDate}&class_interval=1&group_adults=2&group_children=0&keep_landing=1&label_click=undef&no_rooms=1&room1=A%2CA&sb_price_type=total&shw_aparth=1&slp_r_match=0&ss=${city}&ssb=empty&nflt=SustainablePropertyLevelFilter%3D4`;
    return link1;
  }

  function BookingCityHtml({ city }) {
    const queryParameters = new URLSearchParams(window.location.search);
    const startDate = queryParameters.get("startDate");
    const endDate = queryParameters.get("endDate");
    const link = BookingCity(city, startDate, endDate);
    return (
      <h1 className={s.hotel_text}>
        Find sustainable hotels in &nbsp;
        <a
          className={s.hotel_link}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {city}
        </a>
      </h1>
    );
  }

  function handleClick(id) {
    setEditDayNum(id);
    setRegenerateVisible(true);
  }

  return (
    <div className={s.mainWrapper}>
      {loaderShouldShow && (
        <div className={s.loaderDiv}>
          <TourLoader text={"Modifying..."} />
        </div>
      )}

      <div className={s.daysList}>
        {Array.from({ length: currentDaysLength }).map((_, index) => (
          <div className={s.dayCircleWrapper}>
            <div
              className={dayIndex === index ? s.activeDayCircle : s.dayCircle}
              key={index}
              onClick={() => scrollToDay(index)}
            >
              <p>{index + 1}</p>
            </div>
            <p className={dayIndex === index ? s.activeDayText : s.dayText}>
              {dayNames[index]}
            </p>
          </div>
        ))}
        {!freeDoesNotExist && (
          <div className={s.dayCircleWrapper}>
            <div
              onClick={() => scrollToDay("freeTourWrapper")}
              className={s.dayCircle}
              style={{ backgroundColor: "#f07a18", border: "none" }}
            >
              {/* Add content for the extra item here */}
              <img src={free} width={20} />
            </div>
            <p>Free Tours</p>
          </div>
        )}
      </div>
      <div className={s.titlePart}>
        <h1>{data.day_title}</h1>
        <h2>{data.day_date}</h2>
        <img onClick={() => handleClick(dayIndex + 1)} src={edit} />
      </div>
      <div className={s.dayBlocks}>
        <div>
          <h1 className={s.dayTitle}>Morning</h1>
          <StringToParagraph text={data.morning} />
        </div>
        <div>
          <h1 className={s.dayTitle}>Afternoon</h1>
          <StringToParagraph text={data.afternoon} />
        </div>
        <div>
          <h1 className={s.dayTitle}>Evening</h1>
          <StringToParagraph text={data.evening} />
        </div>
        <div>
          <h1 className={s.dayTitle}>Transportation</h1>
          <StringToParagraph text={data.transport} />
          <p>
            We recommend renting a{" "}
            <a style={{fontWeight: "bold"}} href="https://www.booking.com/cars/index.html?aid=7905319">
              Hybrid or EV Car
            </a>
          </p>
        </div>
      </div>

      <div className={s.treeTextDiv}>
        <img src={tree} />
        <h1 className={s.treeText} >Every time you complete booking we plant a tree for you!</h1>
      </div>

      {viatorExists && (
        <div className={s.viatorDiv}>
          {viatorLinks.map((link, index) => (
            <div className={s.viatorCard}>
              <div className={s.viatorImageDiv}>
                <img src={viatorImages[index]} />
              </div>
              <div className={s.viatorCardText}>
                <img src={location} />
                <p>{viatorTitles[index]}</p>
              </div>
              <button onClick={() => window.open(link)}>Book Now</button>
            </div>
          ))}
        </div>
      )}

      <div className={s.footer}>
        <img src={footerHouse} />
        <BookingCityHtml city={data.day_loc} />
      </div>
    </div>
  );
};

export default Day;
