import React from "react";
import "../style/footerpages.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import background from "../assets/svg/WEB Hero.svg";

const PrivacyPolicy = () => {
  return (
    <div>

      <div className="hero">
        <Header />
        <p className="firstText">Privacy Policy</p>
        {/* <img className="backgroundImg" src={background} /> */}
      </div>

      <div className="body">
        <p className="title">
          The purpose of this “Privacy Policy” hereby is to protect the personal
          data rights of the users/visitors of “Biliki.AI” Platform in terms of
          rightful treatment and protection. Under these terms of “Privacy
          Policy” we provide you with the exhaustive information about the usage
          of any of your personal data.
        </p>

        <p className="title">
          By using this Platform/entering and/or registering on the Site you
          give your consent to and accept the following conditions:
        </p>

        <p className="title">1. Information we collect</p>

        <p>
          1.1. During visiting and using this platform and/or the registration
          process for this Site, you provide us with information of email. This
          information is used to contact the user about the services on our site
          for which they have expressed interest. <br /> 1.1.1. During
          registration you register only with email and no additional
          information is required. <br />
          1.1.2. In some cases, we may ask for additional information for
          security reasons or to provide specific services to you. <br /> 1.2 We
          reserve the right to change certain settings of your account
          concerning news subscription, banners, product offerings and other
          means of fulfillment of this Site's content and services in certain
          circumstances, related to marketing and promotional activities.
        </p>

        <p className="title">2. How we use collected information.</p>

        <p>
          2.1. We use the information we collect to try to provide a safe,
          efficient, and customized experience. The main aim of use of collected
          information is to provide our services and features to you, to measure
          and improve those services and features, and to provide you with
          customer support. <br /> 2.2. We use collected information internally
          to improve our marketing efforts, analyze the Platform usage, improve
          our content and product offerings and customize the content of the
          Platform and layout. 
          <br /> 2.3. We may use collected information to deliver information
          that, in some cases, is targeted to your interests, such as banners
          and promotions. <br /> 2.4. We may also send you periodic
          informational updates via email, provided telephone numbers and/or all
          other contact sources. <br />
          2.5. We may use collected information to resolve disputes,
          troubleshoot problems and enforce any agreements, policies and rules
          governing the use of this Platform.
        </p>

        <p className="title"> 3. Disclosure of collected information</p>

        <p>
          3.1. We DO NOT sell or rent any of your personal information to third
          parties for marketing purposes without your permission. However, we do
          provide collected information to our service providers as reasonably
          necessary or appropriate to provide services on the Platform, <br />{" "}
          3.2. We do disclose information in the aggregate to advertisers and
          for other marketing and promotional purposes. However, in these
          situations, we DO NOT disclose information in any form that could be
          used to identify you personally. 3.3. <br /> We expressly reserve the
          right to disclose your personal information when we have a good faith
          belief that disclosure is necessary to protect our rights, or to
          enforce our agreements, policies, and rules governing your use of the
          Platform, or to cooperate voluntarily with law enforcement inquiries.
        </p>

        <p className="title">4. Your use of members' information</p>

        <p>
          4.1. You may not use another members' information, whether private or
          not, for commercial purposes, to spam, to harass, or to make unlawful
          threats. We reserve the right to terminate those who misuse other
          members' information or who otherwise violate any user agreements,
          policies, or rules governing the use of the Site.
        </p>

        <p className="title">5. Deletion of personal data</p>

        <p>
          5.1. Your personal information may be deleted from our database upon
          request to Biliki.AI Email Address. We undertake to perform the
          deletion within one month (30 calendar days) and will send you a
          confirmation once the information has been deleted. Wherever possible,
          we will aim to complete the request in advance of the deadline.
        </p>

        <p className="title">6. Security</p>

        <p>
          6.1. This Site use reasonable security measures to help protect and
          prevent the loss, misuse, and alteration of the information under our
          control.
        </p>

        <p className="title">7. Reservations to this Privacy Policy</p>

        <p>
          7.1. The Site reserves its right, in their sole and absolute
          discretion, to revise, amend, modify or revoke this Privacy Policy at
          any time and in any manner to the fullest extent permitted by law.
          Changes to this Privacy Policy will be effective by posting revisions
          on the Site's Privacy Policy page. 7.2. Any changes posted on this
          page will be effective from the da <br />
          te of posting. <br /> 7.3. It is your responsibility to check Privacy
          Policy and terms of use of the Site.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
