import React, { useState, useEffect } from "react";
import Pagination from "../Pagination";
import s from "../../style/admin.module.css";

const Trips = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [amountTrips, setAmountTrips] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rawTextVisible, setRawTextVisible] = useState(false);
  const [rawText, setRawText] = useState("");

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getAmountTrips`;
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        setAmountTrips(data[0].totalRows);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/getTrips?page=${currentPage}&limit=${itemsPerPage}`
      );
      const result = await response.json();
      setLoading(false);
      setCurrentItems(result);
    };
    fetchData();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    const totalPagesTemp = Math.ceil(amountTrips / itemsPerPage);
    setTotalPages(totalPagesTemp);
  }, [amountTrips]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  function handleRawTextClick(text) {
    setRawTextVisible(true);
    setRawText(text);
  }

  function handleRawTextClose() {
    setRawTextVisible(false);
    setRawText("");
  }

  return loading ? (
    <div className={s.loading}>
      <p></p>
    </div>
  ) : (
    <div>
      <p className={s.title}>Trips</p>
      <div className={s.tripsHead}>
        <p>Destination</p>
        <p>Created Date</p>
        <p>Interests</p>
        <p>Dates</p>
        <p>Trip Link</p>
        <p>AI text</p>
      </div>

      {rawTextVisible && (
        <div className={s.rawTextWrapper}>
          <div className={s.rawTextInnerWrapper}>
            <button className={s.rawTextClose} onClick={handleRawTextClose}>
              Close
            </button>
            <p>{rawText}</p>
          </div>
        </div>
      )}

      {currentItems.map((trip) => {
        const interests = trip.interests.replace(/,/g, ", ");
        const dateStr = trip.wsdate;
        const date = new Date(dateStr);
        const day = date.getDate();
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear();
        const getDaySuffix = (day) => {
          if (day > 3 && day < 21) return "th"; // Covers 11th, 12th, 13th, etc.
          switch (day % 10) {
            case 1:
              return "st";
            case 2:
              return "nd";
            case 3:
              return "rd";
            default:
              return "th";
          }
        };
        const formattedDate = `${day}${getDaySuffix(day)} ${month} ${year}`;

        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        const tripLink = `${baseUrl}/tour?uniqueID=${trip.tour_id}&destination=${trip.destination_title}&startDate=${trip.trip_from}&endDate=${trip.trip_to}&interests=${trip.interests}`;

        return (
          <div key={trip.tour_id} className={s.tripRow}>
            <p className={s.destination}>{trip.destination_title !== null ? trip.destination_title : "null"}</p>
            <p>{formattedDate}</p>
            <p className={s.interests}>{interests}</p>
            <p>
              {trip.trip_from} - {trip.trip_to}
            </p>
            <p className={s.triplink} onClick={() => window.open(tripLink, "_blank")}>
              Trip
            </p>
            <p className={s.triplink} onClick={() => handleRawTextClick(trip.raw_texts)}>
              text
            </p>
          </div>
        );
      })}

      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </div>
  );
};

export default Trips;
