import React, { useState, useEffect } from "react";
import s from "../../style/admin/HomeTexts.module.css";
import { IoClose } from "react-icons/io5";

const Popup = ({ currentData, field, onSave, onClose, error }) => {
  const [inputValue, setInputValue] = useState("");

  const handleSave = () => {
    if (inputValue === "") {
      onSave(null, "Enter a value");
    } else {
      onSave(inputValue);
    }
  };

  return (
    <div className={s.popup} onClick={onClose}>
      <div className={s.popupInnerWrapper} onClick={(e) => e.stopPropagation()}>
        <p>
          <span className={s.curseive}>Current {field}:</span> {currentData}
        </p>
        <IoClose className={s.close} onClick={onClose} size={30} />
        <input
          type="text"
          placeholder={`New ${field}`}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button className={s.saveButton} onClick={handleSave}>
          Save
        </button>
        {error && <p className={s.error}>{error}</p>}
      </div>
    </div>
  );
};

const HomeTexts = () => {
  const [popup, setPopup] = useState("");
  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/getHomeTexts`);
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchData();
  }, [refresh]);

  const handleSave = (newValue, errorMessage) => {
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    setError("");
    setPopup("");

    const api = `${process.env.REACT_APP_API_URL}/updateHomeText`;
    const postData = {
      field: popup,
      value: newValue,
    };

    fetch(api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then(() => {
        setRefresh(!refresh)
      });
  };

  return (
    <div className={s.wrapper}>
      <p className={s.title}>Home Page</p>
      <div className={s.contentWrapper}>
        {[
          "Title",
          "Subtitle",
          "Benefits",
          "Tree title",
          "Tree description",
          "Tree number",
          "Generated Tours Title",
          "Generated Tours Description",
          "Numbers Title",
          "Numbers description",
          "Numbers trees number",
          "Sustainibility title",
          "sustainibility description",
          "SDG 8 target",
          "SDG 8 text",
          "SDG 17 target",
          "SDG 17 text",
          "SDG 13 target",
          "SDG 13 text",
          "Partnership Text",
        ].map((field) => (
          <div className={s.box} key={field} onClick={() => setPopup(field.toLowerCase())}>
            <p className={s.boxTitle}>{field}</p>
            <p className={s.boxContent}>{data[field.toLowerCase()]}</p>
            {/* <p>Home page {field.toLowerCase()}</p> */}
          </div>
        ))}
      </div>

      {popup && (
        <Popup currentData={data[popup]} field={popup} onSave={handleSave} onClose={() => setPopup("")} error={error} />
      )}
    </div>
  );
};

export default HomeTexts;
