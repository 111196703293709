import React from "react";
import "../style/footerpages.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import background from "../assets/svg/WEB Hero.svg";

const Terms = () => {
  return (
    <div>
      <div className="hero">
        <Header />
        <p className="firstText">Terms and Conditions</p>
        {/* <img className="backgroundImg" src={background} /> */}
      </div>

      <div className="body">
        <p className="title">
          By accessing or using Biliki.AI, you agree to be bound by these terms
          of use (hereinafter reffered as to “Terms of Use”).{" "}
        </p>

        <p className="title">
          The service is owned and controlled by “Biliki Brothers LLC”
          (hereinafter reffered as to “Company”). These Terms of Use affect your
          rights and obligations. If you don’t agree to be bound by all these
          Terms of Use, don’t access or use Biliki.Ai.
        </p>

        <p className="title" >1. Scope and Nature of our Service</p>

        <p>
          1.1. Biliki.AI is intended to serve to travelers from all around world
          who desire to plan the trip and travell all around the world.
          Platofrms offers the travelers AI based sustainable trip planner,
          based on destination, budget, interests and duration. Biliki.AI
          provides the travelers with the nesseccary information about
          itinerary, hotels, flights, places to visit and etc. Integrated Google
          Bard generates the trip in seconds <br />. 1.2. You must be 18 or over
          to register as a member of or use this platform. You hereby represent
          and warrant to the company that: (a) you have the full power and
          authority to enter into and perform under these Terms and Conditions;
          (b) your use of this platform will not infringe the copyright,
          trademark, right of publicity or any other legal right of any third
          party; (c) you will comply with all platform laws in using the Site
          and in engaging in all other activities arising from, relating to or
          connected with these Terms.
        </p>

        <p className="title">2. General Conditions</p>

        <p>
          2.1. Information contained by the Biliki.AI is the intangible property
          of the company. The visitor/member is not allowed to distribute/use
          the information for commercial/private purposes without prior written
          assent of the company. <br /> 2.2. The Information presented in the
          Biliki.AI aims to give reccomendations and is of advisory nature,
          therefore, it can not be considered by the visitor/member of the
          platform as the direct/indirect responsibility of the company for the
          organizing the trip, safety and budgete-planing related issues <br />{" "}
          2.3. Although the company will renew the information presented in the
          platform on a regular basis, we don’t take the responsibility that all
          the information will match and it is the visitor/member who takes the
          burden. 
        </p>

        <p className="title">3. Conduct of the Member/Visitor</p>

        <p>
          3.1. In your use of this platform, you must act responsibly and
          exercise good judgment. By way of example and not limitation, you
          shall not <br/> 3.1.1. Violate any applicable law or regulation; <br/> 3.1.2.
          Infringe the rights of any third party, including without limitation,
          intellectual property, privacy, publicity or contractual rights; <br/>
          3.1.3. Use information obtained through the platform for any
          unauthorized purpose; <br/> 3.1.4. Interfere with or damage the platform,
          including, without limitation, through the use of viruses, cancel
          bots, Trojan horses, harmful code, flood pings, denial-of-service
          attacks, packet or IP spoofing, forged routing or electronic mail
          address information or similar methods or technology; <br/> 3.1.5. Use the
          platform in connection with the distribution of spam (which shall be
          defined as unsolicited bulk e-mail or any other e-mail of a
          commercial, religious or political or other nature not within the
          intended purposes of the platform); <br/> 3.1.6. Attempt to defeat any
          security or verification measure relating to use of the platform;
        </p>

        <p className="title">4. Disclaimer:</p>

        <p>4.1.1. Biliki AI utilizes a combination of services and content provided by external sources, including but not limited to Vertex AI, Google Maps, and Booking.com. While we strive to offer accurate and up-to-date information, Biliki AI does not guarantee the reliability or accuracy of the content sourced from these third-party services. We are not responsible for any discrepancies or issues that may arise from the use of this content. Users are advised to verify the information independently and exercise discretion while making travel plans based on the data provided through our platform.</p>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;

