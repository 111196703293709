import React from "react";
import "../style/footerpages.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import background from "../assets/svg/WEB Hero.svg";

const AboutUs = () => {
  return (
    <div>
      <div className="hero">
        <Header />
        <p className="firstText">About Us</p>
        {/* <img className="backgroundImg" src={background} /> */}
      </div>

      <div className="body">
        <p>
          Welcome to Biliki AI, where technology meets sustainable travel. We believe in power exploration, but we also
          understand the impact our journey can have on the planet. That’s why we’ve created a platform that not only
          helps you discover the world’s most remarkable destinations but also ensures your travel choices are
          sustainable and responsible.
        </p>

        <p className="title">Our Mission</p>

        <p>
          Our mission is to make sustainable travel accessible for everyone, contributing directly to SDGs by promoting
          responsible consumption, reducing carbon emissions, and supporting local economies and cultures.
        </p>

        <p className="title">What we do:</p>

        <p>
          With Biliki AI, you can generate personalized sustainable travel plans within seconds, significantly reducing
          the time and effort required for trip planning. You just need to input only destination, dates and interests.
          Within seconds our platform offers AI-generated itineraries, carbon footprint estimations, options to book
          sustainable accommodations and experiences, and recommendations for eco-friendly transportation, all tailored
          to travelers' destinations, interests and dates.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
