import React from "react";
import { useState, useEffect } from "react";
import Pagination from "../Pagination";
import s from '../../style/admin/destinations.module.css'

const Destinations = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [currentItems, setCurrentItems] = useState([]);
  const [amount, setAmount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const api = `${process.env.REACT_APP_API_URL}/getAmountDestinations`;
    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        setAmount(data[0].totalRows);
      });
  }, []);

  useEffect(() => {
    const totalPagesTemp = Math.ceil(amount / itemsPerPage);
    setTotalPages(totalPagesTemp);
  }, [amount]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/getDestinations?page=${currentPage}&limit=${itemsPerPage}`
      );
      const result = await response.json();
      console.log(result);
      setCurrentItems(result);
    };
    fetchData();
  }, [currentPage, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <p className={s.title} >Destinations</p>
      {currentItems.map((item) => (
        <div className={s.items} key={item.id}>
          <li>{item.title}</li>
        </div>
      ))}
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </div>
  );
};

export default Destinations;
